




import Vue from 'vue'
import Component from 'vue-class-component'
import VueFrame from '../components/VueFrame.vue'

@Component({
  components: { VueFrame },
})
export default class Search extends Vue {
  get url() {
    return '/U/starthere/main?sq=' + this.query
  }

  get query() {
    return this.$route.params.query
  }
}
